@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');

$font-family: Quicksand, Verdana, sans-serif;
$hero-title-font-weight: 500;

$primary-color: #1f6cf1;
$accent-color: #21c197;
$warning-color: #ec5402;
$important-color: #ffd902;
$light-gray-color: #64788b;
$article-link-color: #1f6cf1;

$main-background: linear-gradient(135deg, $primary-color 0%, $accent-color 100%);

@import 'open-project-mixins';
@import 'jekyll-theme-open-project';

@import 'relaton';

// body.layout--home > .underlay > .hero {
//   .illustration {
//     bottom: -100px !important;

//     svg {
//       height: 200px;
//     }
//   }

//   > .text {
//     > .title {
//       font-weight: normal;
//     }
//   }
// }


// body.layout--home {
//   > main .custom-intro .summary {
//     ul.supported-bibliographies {
//       li {
//         .label {
//           margin-right: 1en;
//         }
//         .source, .gem {
//           white-space: nowrap;
//         }
//         .source {
//           &:after {
//             content: ", ";
//           }

//           &:last-child:after {
//             content: unset;
//           }
//         }
//       }
//     }
//   }
// }
